import {scenarioTriggers} from "./scenarioTriggers";

export const triggerLabels = {
    [scenarioTriggers.allAlarmTypes]: 'trigger.allAlarmTypes',
    [scenarioTriggers.genericAlarm]: 'genericAlarm',
    [scenarioTriggers.manDown]: 'manDown',
    [scenarioTriggers.sosButton]: 'sosButton',
    [scenarioTriggers.ripcord]: 'ripcord',
    [scenarioTriggers.timer]: 'timer',
    [scenarioTriggers.alarmClosed]: 'alarmClosed',
    [scenarioTriggers.assistanceRequestClosed]: 'assistanceRequestClosed',
    [scenarioTriggers.connectionChanged]: 'connectionChange',
    [scenarioTriggers.quickButton]: 'quickButton',
    [scenarioTriggers.beaconBattery]: 'trigger.beaconBattery',
    [scenarioTriggers.geofenceEntered]: 'enterGeofence',
    [scenarioTriggers.geofenceAttendance]: 'geofenceAttendance',
    [scenarioTriggers.geofenceExited]: 'exitGeofence',
    [scenarioTriggers.idle]: 'idle',
    [scenarioTriggers.docked]: 'docked',
    [scenarioTriggers.undocked]: 'undocked',
    [scenarioTriggers.lowBattery]: 'lowBattery',
    [scenarioTriggers.smsMessage]: 'smsMessage',
    [scenarioTriggers.webhook]: 'webhook',
    [scenarioTriggers.ioChange]: 'ioChange',
    [scenarioTriggers.email]: 'emailMessage',
    [scenarioTriggers.espa]: 'espaEvent',
    [scenarioTriggers.multiTenant]: 'multiTenant',
    [scenarioTriggers.configError]: 'configError',
    [scenarioTriggers.messageNotAcknowledged]: 'messageNotAcknowledged',
    [scenarioTriggers.checkAssistanceRequestResponses]: 'checkAssistanceRequestResponses',
    [scenarioTriggers.arcAlarm]: 'arcAlarm',
    [scenarioTriggers.scenarioDisabled]: 'scenarioDisabled'
};